import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={12} className="home-header" >
            <p style={{ padding: 50, textAlign: "center" }}>
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hey There!{""}
              </h1>
              

              <h1 className="heading-name">
                I'm
                <b className="main-name"> Jayson Gonzalez</b>

                
              </h1>
              
                <Type />
                
              

              </p>
            </Col>

           
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
