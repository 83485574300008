import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import robot from "../../Assets/Projects/robotPro.png";
import data from "../../Assets/Projects/appLo.png";
import webLog from "../../Assets/Projects/lastwe.png";
import numerical from "../../Assets/Projects/numerCo.png";
import manhat from "../../Assets/Projects/MCLoW.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
           Recent <strong className="purple">Projects </strong>
        </h1>
        
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={webLog}
              title="Web Portfolio"
              description="This Website you are currently on was developed using Javascript and React. It is used to display my skills and knowledge of my field. I am hosting it via a raspberry pi. Learning how to host a web server was quite a fun challenge and it is definitely something I will do again. "
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={manhat}
              title="Redesign of MC App"
              description="A collaborative effort among multiple students has been initiated to contribute to the reconstruction of the school-issued app. Utilizing JavaScript and React, we aim to facilitate a seamless building process. As a board member and tech lead, I actively participate in programming activities, overseeing the app's progress, and playing a key role in decision-making for its future features and enhancements."
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={data}
              title="Appstore Database"
              description="In developing a comprehensive database resembling an app store, I incorporated various entities, including apps, developers, companies, storage, and even an editor's choice section. This database serves as a robust repository for a wide range of information. To establish a seamless connection between the database and the front-end, I integrated MySQL with Python. Leveraging Python as the front-end language, I implemented features that allow users to easily access functionalities such as searching for apps, developers, apps within a specific genre, and more."
                            
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={robot}
              title="Robot Profiles"
              description="Employing React JavaScript and CSS, I designed a web app centered around profiles. Within the app, profiles are presented in interactive cards, each dynamically organized for user engagement. The profile information is sourced from an external file included in the project's source files. Each card showcases essential details such as name, username, email, and features a distinct photo retrieved through a web API, enhancing the app's visual appeal and user experience."
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={numerical}
              title="Non-Linear Equation Solver"
              description="Using C++ and an external gui libraries, I was able to create an application that is able to utilize the Bisection, Newton, Secant, and False Position Methods. The application is able to calculate the root and how many iterations it took to obatin the answer. All while maintaing a user-friendly experience."

            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
