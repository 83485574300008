import React from "react";
import Card from "react-bootstrap/Card";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "center" }}>
            Hey There! 
            <span className="wave" role="img" aria-labelledby="wave">
                  👋🏽
                </span>
            My name is <span className="purple">Jayson Gonzalez, </span>
            I'm from <span className="purple"> New Jersey.</span>
            <br />
            I'm currently a Computer Science Student at Manhattan College who is set to graduate in Spring 2025
            <br />
            <br />
            Some other things I enjoy doing consist of
               <br />
               Working out
               <br />
               Trying New Foods
               <br />
               Travelling    
</p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
